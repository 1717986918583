import { useOverlayScale } from "../editor/hooks";

type Props = {
  cx: number;
  cy: number;
  onInsert: () => void;
};

export default function VertexInsertionHandle({
  cx,
  cy,
  onInsert,
}: Props): JSX.Element {
  const overlayScale = useOverlayScale();

  const onMouseDown = (event: React.MouseEvent<SVGCircleElement>) => {
    event.stopPropagation();
    onInsert();
  };

  return (
    <circle
      cx={cx}
      cy={cy}
      r={8 / overlayScale}
      fillOpacity={0.5}
      stroke="transparent"
      strokeWidth={2 / overlayScale}
      className="cursor-pointer fill-none hover:fill-brand"
      onMouseDown={onMouseDown}
      tabIndex={0}
    />
  );
}
