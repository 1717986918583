import { ColoredRegionFragment } from "../client/generated";
import { PossibleTypesResultData } from "../client/generated/introspection";

export type AttributeKind =
  PossibleTypesResultData["possibleTypes"]["Attribute"][number];

const ATTRIBUTE_PRIORITY: Record<AttributeKind, number> = {
  HeaderLevelAttribute: 0,
  CaptionAttribute: 1,
  ExcludeReadingOrderAttribute: 2,
  ExpressionAttribute: 3,
  FigureAttribute: 4,
  ImageAttribute: 5,
  ListAttribute: 6,
  ListItemAttribute: 7,
  ListItemLabelAttribute: 8,
  PaginationArtifactAttribute: 9,
  TableAttribute: 10,
  TableCellAttribute: 11,
  TableHeaderCellAttribute: 12,
  TableOfContentsAttribute: 13,
  TableOfContentsHeadingAttribute: 14,
  TableOfContentsItemAttribute: 15,
  TableOfContentsPageNumberAttribute: 16,
  TextAttribute: 17,
  AlternativeTextAttribute: 18,
};

export function getPrimaryAttributeKind(
  region: ColoredRegionFragment,
): AttributeKind | undefined {
  const sortedAttributes = [...region.attributes].sort(
    (a, b) =>
      ATTRIBUTE_PRIORITY[a.__typename] - ATTRIBUTE_PRIORITY[b.__typename],
  );

  return sortedAttributes.at(0)?.__typename;
}

export function makeSelectableRegionPreviewID(regionId: string) {
  return `selectable-region-preview-${regionId}`;
}
