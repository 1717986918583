import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabIdentifier,
  TabPrefix,
} from "../../editor/reducers";
import {
  TableOfContentsItemUpdateTabFragment,
  useTableOfContentsItemUpdateTabSuspenseQuery,
  useUpdateTableOfContentsItemAttributeMutation,
} from "../../client/generated";
import AttributeDeleteButton from "../AttributeDeleteButton";
import { useTranslation } from "react-i18next";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import TableOfContentsRegionSelect from "./TableOfContentsRegionSelect";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { assertTypeName } from "../../client/guards";

type Props = {
  fragment: TableOfContentsItemUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function TableOfContentsItemUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableOfContentsItemUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TableOfContentsItemUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <Body dispatch={dispatch} fragment={fragment} />
    </NavbarTabContent>
  );
}

function Body({ dispatch, fragment }: Props) {
  const { t } = useTranslation();

  const { data } = useTableOfContentsItemUpdateTabSuspenseQuery({
    variables: {
      attributeId: fragment.id,
    },
  });

  assertTypeName(data.node?.__typename === "TableOfContentsItemAttribute");

  const [linkedRegion, setLinkedRegion] = useState(data.node.linkedRegionId);

  const [updateTableOfContentsItemAttribute, { loading }] =
    useUpdateTableOfContentsItemAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateTableOfContentsItemAttribute({
      variables: {
        attributeId: fragment.id,
        linkedRegionId: linkedRegion ?? "",
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <form className="flex flex-col p-4 gap-4" onSubmit={onSubmit}>
      <TableOfContentsRegionSelect
        fragment={data.node.region.page.document}
        value={linkedRegion ?? undefined}
        onValueChange={setLinkedRegion}
      />
      <AccessButton type="submit" loading={loading}>
        {t("TableOfContentsItemUpdateTab.labels.confirm")}
      </AccessButton>
      <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
    </form>
  );
}
