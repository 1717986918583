import dayjs, { extend } from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTranslation } from "react-i18next";

extend(duration);
extend(relativeTime);

export default function useHumanizedDiff(date: dayjs.ConfigType): string {
  const { i18n } = useTranslation();
  const diff = dayjs().diff(date);
  return dayjs.duration(-diff).locale(i18n.language).humanize(true);
}
