import { Button } from "@acdc2/ui/components/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@acdc2/ui/components/popover";
import { useState } from "react";
import { Icon } from "@mdi/react";
import { mdiUnfoldMoreHorizontal, mdiCheck } from "@mdi/js";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@acdc2/ui/components/command";
import { useTranslation } from "react-i18next";
import { ExporterType } from "../client/generated";

type Props = {
  value: ExporterType | undefined;
  onSelect: (exporterType: ExporterType) => void;
};

export default function DocumentExportCombobox({
  value,
  onSelect,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const exporterTypeKeys = Object.keys(ExporterType) as Array<
    keyof typeof ExporterType
  >;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value ?? t("DocumentExportCombobox.label")}
          <Icon path={mdiUnfoldMoreHorizontal} size={1} className="flex" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder={t("DocumentExportCombobox.input")} />
          <CommandList>
            <CommandEmpty>
              {t("DocumentExportCombobox.placeholder")}
            </CommandEmpty>
            <CommandGroup>
              {exporterTypeKeys.map((exporterTypeKey) => {
                const exporterType = ExporterType[exporterTypeKey];

                return (
                  <CommandItem
                    key={exporterType}
                    value={exporterType}
                    onSelect={() => {
                      setOpen(false);
                      onSelect(exporterType);
                    }}
                  >
                    {value === exporterType ? (
                      <Icon path={mdiCheck} size="1rem" className="mr-2" />
                    ) : null}
                    {exporterType}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
