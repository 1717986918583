import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import {
  CaptionUpdateTabFragment,
  useCaptionUpdateTabSuspenseQuery,
  useUpdateCaptionAttributeMutation,
} from "../../client/generated";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { useTranslation } from "react-i18next";
import AttributeDeleteButton from "../AttributeDeleteButton";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import CaptionRegionSelect from "./CaptionRegionSelect";
import { assertTypeName } from "../../client/guards";

type Props = {
  fragment: CaptionUpdateTabFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function CaptionUpdateTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.CaptionUpdate, fragment.id)}
    >
      <NavbarTabHeader
        title={t("CaptionUpdateTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <Body dispatch={dispatch} fragment={fragment} />
    </NavbarTabContent>
  );
}

function Body({ dispatch, fragment }: Props) {
  const { t } = useTranslation();

  const { data } = useCaptionUpdateTabSuspenseQuery({
    variables: { attributeId: fragment.id },
  });

  assertTypeName(data.node?.__typename === "CaptionAttribute");

  const [linkedRegion, setLinkedRegion] = useState(data.node.linkedRegionId);

  const [updateCaptionAttribute, { loading }] =
    useUpdateCaptionAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await updateCaptionAttribute({
      variables: {
        attributeId: fragment.id,
        linkedRegionId: linkedRegion ?? "",
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
      <CaptionRegionSelect
        fragment={data.node.region.page.document}
        value={linkedRegion ?? undefined}
        onValueChange={setLinkedRegion}
      />
      <AccessButton type="submit" loading={loading}>
        {t("CaptionUpdateTab.labels.confirm")}
      </AccessButton>
      <AttributeDeleteButton fragment={fragment} dispatch={dispatch} />
    </form>
  );
}
