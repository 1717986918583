import { mdiDelete } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Button } from "@acdc2/ui/components/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@acdc2/ui/components/tooltip";
import {
  RegionDeleteButtonFragment,
  useDeleteRegionMutation,
} from "../client/generated";
import { Dispatch } from "react";
import { EditorAction } from "../editor/reducers";
import { useTranslation } from "react-i18next";

type Props = {
  fragment: RegionDeleteButtonFragment;
  dispatch: Dispatch<EditorAction>;
};

export default function RegionDeleteButton({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [deleteRegion] = useDeleteRegionMutation({
    variables: { regionId: fragment.id },
    optimisticResponse: {
      deleteRegion: {
        userErrors: [],
        region: {
          __typename: "Region",
          id: fragment.id,
        },
      },
    },
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onClick = async () => {
    const { errors, data } = await deleteRegion();

    if (errors) {
      console.error("Error deleting region:", errors);
    } else if (data?.deleteRegion.userErrors) {
      console.error(
        "User error deleting region:",
        data.deleteRegion.userErrors,
      );
    } else {
      dispatch({ type: "deselectRegion" });
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline" size="icon" onClick={onClick}>
            <Icon path={mdiDelete} size="1rem" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">
          {t("RegionDeleteButton.label")}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
