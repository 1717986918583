import { Dispatch, useState } from "react";
import {
  ReshapeableRegionFragment,
  useUpdateRegionVerticesMutation,
  Vertex,
} from "../client/generated";
import { EditorAction } from "../editor/reducers";
import { CSS_IN_TO_PX } from "../editor/utils";
import { usePageScale } from "../editor/hooks";
import RegionReshapeHandle from "./RegionReshapeHandle";
import RegionPolygon from "./RegionPolygon";
import RegionContextHandler from "./RegionContextHandler";
import RegionSelectHandler from "./RegionSelectHandler";
import RegionDragHandler from "./RegionDragHandler";
import VertexInsertionHandle from "./VertexInsertionHandle";
import { getPrimaryAttributeKind } from "./utils";

type Props = {
  fragment: ReshapeableRegionFragment;
  isSelected: boolean;
  dispatch: Dispatch<EditorAction>;
};

export default function ReshapeableRegion({
  fragment,
  isSelected,
  dispatch,
}: Props) {
  const pageScale = usePageScale();
  const pageWidth = fragment.page.width * CSS_IN_TO_PX;
  const pageHeight = fragment.page.height * CSS_IN_TO_PX;
  const [vertices, setVertices] = useState<Vertex[]>(fragment.shape.vertices);
  const [activeVertexIndex, setActiveVertexIndex] = useState<number>();
  const [isReshaping, setIsReshaping] = useState(false);

  const overlappedVertexIndex = getOverlappedNeighbourIndex(
    vertices,
    activeVertexIndex,
    pageWidth,
    pageHeight,
  );

  const vertexInsertionPoints = isSelected
    ? getVertexInsertionPoints(vertices)
    : [];

  const [mutate] = useUpdateRegionVerticesMutation();

  const onDone = async () => {
    const nonOverlappingVertices = [...vertices];

    if (
      activeVertexIndex !== undefined &&
      overlappedVertexIndex !== undefined
    ) {
      nonOverlappingVertices.splice(activeVertexIndex, 1);
      setActiveVertexIndex(Math.min(activeVertexIndex, overlappedVertexIndex));
    }

    const { data, errors } = await mutate({
      variables: {
        regionId: fragment.id,
        vertices: nonOverlappingVertices.map((vertex) => ({
          left: vertex.left,
          top: vertex.top,
        })),
      },
    });

    if (errors) {
      console.error("Error updating region vertices:", errors);
    } else if (data?.updateRegionShape.userErrors) {
      console.error(
        "User error updating region vertices:",
        data.updateRegionShape.userErrors,
      );
    }

    setVertices(
      data?.updateRegionShape.region?.shape.vertices ?? fragment.shape.vertices,
    );
  };

  const deleteVertex = async (index: number) => {
    const { data } = await mutate({
      variables: {
        regionId: fragment.id,
        vertices: vertices
          .filter((_, i) => i !== index)
          .map(({ top, left }) => ({ top, left })),
      },
    });

    if (data && !data.updateRegionShape.userErrors) {
      setActiveVertexIndex((oldIndex) =>
        oldIndex === index ? undefined : oldIndex,
      );
    }
  };

  return (
    <>
      <RegionDragHandler
        onDrag={(movementX, movementY) => {
          setVertices((oldVertices) => {
            return oldVertices.map((vertex) => ({
              left: vertex.left + movementX / pageWidth / pageScale,
              top: vertex.top + movementY / pageHeight / pageScale,
            }));
          });
        }}
        onDone={onDone}
      >
        <RegionContextHandler
          fragment={fragment}
          isSelected={isSelected}
          dispatch={dispatch}
        >
          <RegionSelectHandler
            fragment={fragment}
            isSelected={isSelected}
            dispatch={dispatch}
          >
            <RegionPolygon
              vertices={vertices}
              attrKind={getPrimaryAttributeKind(fragment)}
            />
          </RegionSelectHandler>
        </RegionContextHandler>
      </RegionDragHandler>

      {isSelected &&
        vertices.map((vertex, index) => (
          <RegionReshapeHandle
            key={index}
            cx={vertex.left * pageWidth}
            cy={vertex.top * pageHeight}
            onMove={(dx, dy) => {
              setIsReshaping(true);
              setVertices((oldVertices) => {
                const newVertices = [...oldVertices];
                newVertices[index] = {
                  left: oldVertices[index].left + dx / pageWidth / pageScale,
                  top: oldVertices[index].top + dy / pageHeight / pageScale,
                };
                return newVertices;
              });
            }}
            onDone={() => {
              setIsReshaping(false);
              onDone();
            }}
            onSelect={() => setActiveVertexIndex(index)}
            onDelete={() => deleteVertex(index)}
            isActive={index === activeVertexIndex}
            isOverlapping={index === overlappedVertexIndex}
          />
        ))}

      {!isReshaping &&
        vertexInsertionPoints.map((midpoint, index) => (
          <VertexInsertionHandle
            key={index}
            cx={midpoint.left * pageWidth}
            cy={midpoint.top * pageHeight}
            onInsert={() => {
              setVertices((oldVertices) => {
                const newVertices = [...oldVertices];
                newVertices.splice(index + 1, 0, midpoint);
                return newVertices;
              });
            }}
          />
        ))}
    </>
  );
}

const MERGE_THRESHOLD = 4;

function getOverlappedNeighbourIndex(
  vertices: Vertex[],
  index: number | undefined,
  pageWidth: number,
  pageHeight: number,
) {
  if (index === undefined) return undefined;
  const vertex = vertices[index];

  const prevIndex = (index - 1 + vertices.length) % vertices.length;
  const prevVertex = vertices[prevIndex];

  if (
    Math.abs(vertex.left - prevVertex.left) * pageWidth < MERGE_THRESHOLD &&
    Math.abs(vertex.top - prevVertex.top) * pageHeight < MERGE_THRESHOLD
  ) {
    return prevIndex;
  }

  const nextIndex = (index + 1) % vertices.length;
  const nextVertex = vertices[nextIndex];

  if (
    Math.abs(vertex.left - nextVertex.left) * pageWidth < MERGE_THRESHOLD &&
    Math.abs(vertex.top - nextVertex.top) * pageHeight < MERGE_THRESHOLD
  ) {
    return nextIndex;
  }

  return undefined;
}

function getVertexInsertionPoints(vertices: Vertex[]) {
  return vertices.map((vertex, index) => {
    const nextIndex = (index + 1) % vertices.length;
    const nextVertex = vertices[nextIndex];
    return {
      left: (vertex.left + nextVertex.left) / 2,
      top: (vertex.top + nextVertex.top) / 2,
    };
  });
}
