import { useNavigate } from "react-router";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import Scaffold from "../shell/Scaffold";
import { routingPaths } from "../navigation/constants";
import Navbar from "../shell/Navbar";
import { ScrollArea } from "@acdc2/ui/components/scroll-area";

type Props = {
  error: unknown;
  componentStack: string;
  eventId: string;
  resetError(): void;
};

export default function ErrorScreen({
  error,
  componentStack,
  eventId,
  resetError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = () => {
    resetError();
    navigate(routingPaths.index);
  };

  return (
    <Scaffold navbar={<Navbar />}>
      <ScrollArea>
        <div className="flex flex-col justify-center items-center gap-4 p-16">
          <div className="text-lg font-bold uppercase">
            {t("ErrorScreen.title")}
          </div>
          <div>{t("ErrorScreen.reference", { id: eventId })}</div>
          <details>
            <summary className="text-center cursor-pointer">
              {t("ErrorScreen.details")}
            </summary>
            <div className="flex flex-col gap-4 font-mono">
              <div className="bg-gray-50 dark:bg-gray-900 p-4">
                {new String(error)}
              </div>
              <pre className="bg-gray-50 dark:bg-gray-900 p-4">
                {componentStack.trimStart()}
              </pre>
            </div>
          </details>
          <Button onClick={onClick}>Go back</Button>
        </div>
      </ScrollArea>
    </Scaffold>
  );
}
