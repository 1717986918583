import { Button } from "@acdc2/ui/components/button";
import { Input } from "@acdc2/ui/components/input";
import { mdiCheck, mdiClose, mdiDelete, mdiPencil } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  defaultValue: string;
  onSave: (author: string) => void;
  onDelete: () => void;
};

export default function MetadataAuthorInput({
  defaultValue,
  onDelete,
  onSave,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editInput, setEditInput] = useState(defaultValue);

  const handleSave = (newAuthor: string) => {
    onSave(newAuthor);
    setIsEditing(false);
  };

  return (
    <div className="flex flex-row gap-2">
      <Input
        defaultValue={defaultValue}
        type="text"
        onChange={(event) => {
          setEditInput(event.target.value);
        }}
        disabled={!isEditing}
        placeholder={t("MetadataAuthorInput.placeholder")}
        required
      />

      <div className="flex flex-row gap-2">
        {isEditing ? (
          <>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => handleSave(editInput)}
            >
              <Icon path={mdiCheck} size={1} />
            </Button>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => setIsEditing(false)}
            >
              <Icon path={mdiClose} size={1} />
            </Button>
          </>
        ) : (
          <>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => setIsEditing(true)}
            >
              <Icon path={mdiPencil} size={1} />
            </Button>
            <Button
              type="button"
              size="icon"
              variant="outline"
              onClick={() => onDelete()}
            >
              <Icon path={mdiDelete} size={1} />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
