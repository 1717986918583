import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabIdentifier,
  TabPrefix,
} from "../../editor/reducers";
import {
  AccessibleHeadingCreationTabFragment,
  useCreateAccessibleHeadingAttributeMutation,
} from "../../client/generated";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import { Button } from "@acdc2/ui/components/button";
import { useTranslation } from "react-i18next";
import { Textarea } from "@acdc2/ui/components/textarea";
import HeadingLevelSelect from "../heading-level/HeadingLevelSelect";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: AccessibleHeadingCreationTabFragment;
};

export default function AccessibleHeadingCreationTab({
  fragment,
  dispatch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [level, setLevel] = useState<string>();
  const [text, setText] = useState<string>();

  const [createAccessibleHeadingAttribute] =
    useCreateAccessibleHeadingAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!level || !text) return;

    await createAccessibleHeadingAttribute({
      variables: {
        regionId: fragment.id,
        level: parseInt(level),
        text,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <NavbarTabContent
      value={makeIdentifier(
        TabPrefix.AccessibleHeadingCreationTab,
        fragment.id,
      )}
    >
      <NavbarTabHeader
        title={t("AccessibleHeadingCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
        <HeadingLevelSelect value={level} onValueChange={setLevel} required />
        <Textarea
          placeholder={t("AccessibleHeadingCreationTab.labels.placeholder")}
          value={text}
          onChange={(event) => setText(event.target.value)}
          required
        />
        <Button type="submit">
          {t("AccessibleHeadingCreationTab.labels.add")}
        </Button>
      </form>
    </NavbarTabContent>
  );
}
