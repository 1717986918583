import { SVGProps } from "react";
import { Vertex } from "../client/generated";
import { cn } from "@acdc2/ui/lib/utils";
import { AttributeKind } from "./utils";
import { useOverlayScale } from "../editor/hooks";

type Props = SVGProps<SVGPolygonElement> & {
  vertices: Vertex[];
  attrKind?: AttributeKind;
};

export default function RegionPolygon({
  vertices,
  attrKind,
  className,
  ...rest
}: Props) {
  const overlayScale = useOverlayScale();

  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none">
      <polygon
        points={vertices
          .map((vertex) => `${vertex.left * 100},${vertex.top * 100}`)
          .join(" ")}
        strokeWidth={2 / overlayScale}
        vectorEffect="non-scaling-stroke"
        tabIndex={0}
        className={cn(
          "outline-none fill-transparent mix-blend-multiply",
          {
            "animate-default-attribute focus:fill-attr-default-mid/50 hover:fill-attr-default-mid/50":
              !attrKind,
            "animate-heading-attribute focus:fill-attr-heading-mid/50 hover:fill-attr-heading-mid/50":
              attrKind === "HeaderLevelAttribute",
            "animate-caption-attribute focus:fill-attr-caption-mid/50 hover:fill-attr-caption-mid/50":
              attrKind === "CaptionAttribute",
            "animate-decoration-attribute focus:fill-attr-decoration-mid/50 hover:fill-attr-decoration-mid/50":
              attrKind === "ExcludeReadingOrderAttribute",
            "animate-expression-attribute focus:fill-attr-expression-mid/50 hover:fill-attr-expression-mid/50":
              attrKind === "ExpressionAttribute",
            "animate-figure-attribute focus:fill-attr-figure-mid/50 hover:fill-attr-figure-mid/50":
              attrKind === "FigureAttribute",
            "animate-image-attribute focus:fill-attr-image-mid/50 hover:fill-attr-image-mid/50":
              attrKind === "ImageAttribute",
            "animate-list-attribute focus:fill-attr-list-mid/50 hover:fill-attr-list-mid/50":
              attrKind === "ListAttribute" ||
              attrKind === "ListItemAttribute" ||
              attrKind === "ListItemLabelAttribute",
            "animate-pagination-attribute focus:fill-attr-pagination-mid/50 hover:fill-attr-pagination-mid/50":
              attrKind === "PaginationArtifactAttribute",
            "animate-table-attribute focus:fill-attr-table-mid/50 hover:fill-attr-table-mid/50":
              attrKind === "TableAttribute" ||
              attrKind === "TableCellAttribute" ||
              attrKind === "TableHeaderCellAttribute",
            "animate-toc-attribute focus:fill-attr-toc-mid/50 hover:fill-attr-toc-mid/50":
              attrKind === "TableOfContentsAttribute" ||
              attrKind === "TableOfContentsHeadingAttribute" ||
              attrKind === "TableOfContentsItemAttribute" ||
              attrKind === "TableOfContentsPageNumberAttribute",
            "animate-text-attribute focus:fill-attr-text-mid/50 hover:fill-attr-text-mid/50":
              attrKind === "TextAttribute",
            "animate-alt-attribute focus:fill-attr-alt-mid/50 hover:fill-attr-alt-mid/50":
              attrKind === "AlternativeTextAttribute",
          },
          className,
        )}
        {...rest}
      />
    </svg>
  );
}
