import useHumanizedDiff from "../i18n/useHumanizedDiff";

type Props = {
  date: string;
};

export default function DocumentDateTableCell({ date }: Props): JSX.Element {
  const humanizedDiff = useHumanizedDiff(date);

  return <div className="first-letter:capitalize">{humanizedDiff}</div>;
}
