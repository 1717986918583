import { Dispatch, useState } from "react";
import {
  EditorAction,
  makeIdentifier,
  TabPrefix,
  TabIdentifier,
} from "../../editor/reducers";
import { AccessButton } from "@acdc2/ui/components/access-button";
import {
  TableOfContentsItemCreationTabFragment,
  useCreateTableOfContentsItemAttributeMutation,
  useTableOfContentsItemCreationTabSuspenseQuery,
} from "../../client/generated";
import { useTranslation } from "react-i18next";
import NavbarTabContent from "../../shell/NavbarTabContent";
import NavbarTabHeader from "../../shell/NavbarTabHeader";
import AttributeCloseButton from "../AttributeCloseButton";
import TableOfContentsRegionSelect from "./TableOfContentsRegionSelect";
import { assertTypeName } from "../../client/guards";

type Props = {
  dispatch: Dispatch<EditorAction>;
  fragment: TableOfContentsItemCreationTabFragment;
};

export default function TableOfContentsItemCreationTab({
  dispatch,
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <NavbarTabContent
      value={makeIdentifier(TabPrefix.TableOfContentsItemCreation, fragment.id)}
    >
      <NavbarTabHeader
        title={t("TableOfContentsItemCreationTab.title")}
        right={<AttributeCloseButton dispatch={dispatch} />}
      />
      <Body dispatch={dispatch} fragment={fragment} />
    </NavbarTabContent>
  );
}

function Body({ dispatch, fragment }: Props) {
  const { t } = useTranslation();

  const { data } = useTableOfContentsItemCreationTabSuspenseQuery({
    variables: {
      regionId: fragment.id,
    },
  });

  assertTypeName(data.node?.__typename === "Region");

  const [linkedRegion, setLinkedRegion] = useState<string>();

  const [createTableOfContentsItem] =
    useCreateTableOfContentsItemAttributeMutation();

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    await createTableOfContentsItem({
      variables: {
        regionId: fragment.id,
        linkedRegionId: linkedRegion,
      },
    });

    dispatch({
      type: "switchTab",
      tabIdentifier: TabIdentifier.Regions,
    });
  };

  return (
    <form className="p-4 flex flex-col gap-4" onSubmit={onSubmit}>
      <TableOfContentsRegionSelect
        fragment={data.node.page.document}
        value={linkedRegion}
        onValueChange={setLinkedRegion}
      />
      <AccessButton type="submit">
        {t("TableOfContentsItemCreationTab.labels.add")}
      </AccessButton>
    </form>
  );
}
