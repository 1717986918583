import { cn } from "@acdc2/ui/lib/utils";
import { RegionThumbnailFragment } from "../client/generated";
import RegionImage from "./RegionImage";
import { getPrimaryAttributeKind } from "./utils";

type Props = {
  fragment: RegionThumbnailFragment;
};

export default function RegionThumbnail({ fragment }: Props): JSX.Element {
  const attrKind = getPrimaryAttributeKind(fragment);
  const viewBoxWidth = fragment.shape.aabb.width * fragment.page.width;
  const viewBoxHeight = fragment.shape.aabb.height * fragment.page.height;

  return (
    <div
      className={cn(
        "min-h-16 flex flex-col justify-center bg-gradient-to-r p-2",
        {
          "from-attr-default-max to-attr-default-min": !attrKind,
          "from-attr-heading-max to-attr-heading-min":
            attrKind === "HeaderLevelAttribute",
          "from-attr-caption-max to-attr-caption-min":
            attrKind === "CaptionAttribute",
          "from-attr-decoration-max to-attr-decoration-min":
            attrKind === "ExcludeReadingOrderAttribute",
          "from-attr-expression-max to-attr-expression-min":
            attrKind === "ExpressionAttribute",
          "from-attr-figure-max to-attr-figure-min":
            attrKind === "FigureAttribute",
          "from-attr-image-max to-attr-image-min":
            attrKind === "ImageAttribute",
          "from-attr-list-max to-attr-list-min":
            attrKind === "ListAttribute" ||
            attrKind === "ListItemAttribute" ||
            attrKind === "ListItemLabelAttribute",
          "from-attr-pagination-max to-attr-pagination-min":
            attrKind === "PaginationArtifactAttribute",
          "from-attr-table-max to-attr-table-min":
            attrKind === "TableAttribute" ||
            attrKind === "TableCellAttribute" ||
            attrKind === "TableHeaderCellAttribute",
          "from-attr-toc-max to-attr-toc-min":
            attrKind === "TableOfContentsAttribute" ||
            attrKind === "TableOfContentsHeadingAttribute" ||
            attrKind === "TableOfContentsItemAttribute" ||
            attrKind === "TableOfContentsPageNumberAttribute",
          "from-attr-text-max to-attr-text-min": attrKind === "TextAttribute",
          "from-attr-alt-max to-attr-alt-min":
            attrKind === "AlternativeTextAttribute",
        },
      )}
    >
      <div
        className="mx-auto drop-shadow-lg"
        style={{ width: `${viewBoxWidth}in`, height: `${viewBoxHeight}in` }}
      >
        <RegionImage fragment={fragment} />
      </div>
    </div>
  );
}
